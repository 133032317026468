<template>
  <v-container>
    <div class="ml-2">
      <PageTitle
        class="font font-weight-bold "
        text="Add New Customer"
        style="text-align: center; font-size: 22px;"
      />
    </div>
    <v-row>
      <v-col cols="12">
        <v-tabs centered light>
          <v-tabs-slider></v-tabs-slider>
          <v-tab>
            <span class="font font-weight-medium font-size-sm"
              >As A Business</span
            >
          </v-tab>

          <v-tab>
            <span class="font font-weight-medium font-size-sm"
              >As An Individual</span
            >
          </v-tab>

          <v-tab>
            <span class="font font-weight-medium font-size-sm">
              As A Supplier</span
            >
          </v-tab>
          <v-tab-item>
            <v-card
              class="box-shadow-light pt-3 pb-8 mb-4 mt-3 px-2"
              style="border-radius: 8px;width: 60%;margin: 0 auto;"
            >
              <v-card-text>
                <ValidationObserver
                  class="d-flex flex-column flex-grow-1"
                  v-slot="{ handleSubmit }"
                >
                  <form @submit.prevent="handleSubmit(addAsBusiness)">
                    <ValidationProvider
                      tag="div"
                      class="font d-flex flex-row flex-grow-1"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <i
                        :class="errors.length > 0 ? 'red--text' : 'black--text'"
                        class="material-icons-outlined mt-4 mr-3"
                        >verified_user</i
                      >
                      <v-text-field
                        autofocus
                        class="font font-weight-medium d-flex flex-row flex-grow-1"
                        label="Business Registration Number"
                        clearable
                        :error-messages="errors[0]"
                        v-model="business.businessRegistrationNumber"
                        hint="Enter registration no. here"
                      />
                    </ValidationProvider>
                    <div class="d-flex flex-row flex-grow-1">
                      <ValidationProvider
                        tag="div"
                        class="font d-flex flex-row flex-grow-1"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <i
                          :class="
                            errors.length > 0 ? 'red--text' : 'black--text'
                          "
                          class="material-icons-outlined mt-4 mr-3"
                          >store</i
                        >
                        <v-text-field
                          autofocus
                          class="font font-weight-medium d-flex flex-row flex-grow-1"
                          label="Business Name"
                          clearable
                          :error-messages="errors[0]"
                          v-model="business.name"
                          hint="Enter business name here"
                        />
                      </ValidationProvider>
                      <ValidationProvider
                        tag="div"
                        class="font d-flex flex-row flex-grow-1"
                        rules="required|email"
                        v-slot="{ errors }"
                      >
                        <i
                          :class="
                            errors.length > 0 ? 'red--text' : 'black--text'
                          "
                          class="material-icons-outlined mt-4 mr-3"
                          >email</i
                        >
                        <v-text-field
                          class="font font-weight-medium d-flex flex-row flex-grow-1"
                          label="Business Email"
                          clearable
                          :error-messages="errors[0]"
                          v-model="business.email"
                          hint="Enter business email here"
                        />
                      </ValidationProvider>
                    </div>

                    <div class="d-flex flex-row flex-grow-1">
                      <ValidationProvider
                        tag="div"
                        class="font d-flex flex-row flex-grow-1"
                        rules="required|numeric|max:12"
                        v-slot="{ errors }"
                      >
                        <i
                          :class="
                            errors.length > 0 ? 'red--text' : 'black--text'
                          "
                          class="material-icons-outlined mt-4 mr-3"
                          >phone</i
                        >
                        <v-text-field
                          class="font font-weight-medium d-flex flex-row flex-grow-1"
                          label="Business Contact"
                          clearable
                          :error-messages="errors[0]"
                          v-model="business.contact"
                          hint="Enter business contact here"
                        /> </ValidationProvider
                      ><ValidationProvider
                        tag="div"
                        class="font d-flex flex-row flex-grow-1"
                      >
                        <i class="material-icons-outlined mt-4 mr-3">map</i>
                        <v-text-field
                          class="font font-weight-medium d-flex flex-row flex-grow-1"
                          label="Business Address"
                          clearable
                          v-model="business.address"
                          hint="Enter address here"
                        />
                      </ValidationProvider>
                    </div>
                    <div class="d-flex flex-row flex-grow-1">
                      <ValidationProvider
                        tag="div"
                        class="font d-flex flex-row flex-grow-1"
                      >
                        <i class="material-icons-outlined mt-4 mr-3"
                          >location_on</i
                        >
                        <v-text-field
                          v-model="business.officeLocation"
                          class="font font-weight-medium d-flex flex-row flex-grow-1"
                          label="Office Location"
                          clearable
                          hint="Enter office location here"
                        />
                      </ValidationProvider>
                      <ValidationProvider
                        tag="div"
                        class="font d-flex flex-row flex-grow-1"
                      >
                        <i class="material-icons-outlined mt-4 mr-3"
                          >credit_card</i
                        >
                        <v-text-field
                          v-model="business.tin"
                          class="font font-weight-medium d-flex flex-row flex-grow-1"
                          label="Business TIN"
                          clearable
                          hint="Enter business tin here"
                        />
                      </ValidationProvider>
                    </div>

                    <div class="d-flex flex-column flex-grow-1 pa-2">
                      <h3 class="font font-weight-bold font-size-md py-1">
                        Add Board Members OR Directors
                      </h3>
                      <div
                        v-for="(director, index) in business.directors"
                        :key="index"
                        style="border-radius: 8px; background: rgb(232 232 232 / 41%);"
                        class="d-flex flex-row flex-grow-1 px-4 my-1"
                      >
                        <i class="material-icons-outlined mt-4 mr-3"
                          >account_circle</i
                        >
                        <ValidationProvider
                          rules="required"
                          tag="div"
                          v-slot="{ errors }"
                          class="d-flex flex-row flex-grow-1"
                        >
                          <v-text-field
                            class="font font-weight-medium"
                            label="Full Name"
                            :error-messages="errors[0]"
                            hint="Enter directors' full name here ..."
                            v-model="director.name"
                          />
                        </ValidationProvider>
                        <ValidationProvider
                          rules="required|email"
                          tag="div"
                          v-slot="{ errors }"
                          class="d-flex flex-row flex-grow-1"
                        >
                          <i
                            :class="
                              errors.length > 0 ? 'red--text' : 'black--text'
                            "
                            class="material-icons-outlined mt-4 mr-3"
                            >email</i
                          >
                          <v-text-field
                            class="font font-weight-medium"
                            label="Email Address"
                            :error-messages="errors[0]"
                            hint="Enter directors' email address here ..."
                            v-model="director.email"
                          />
                        </ValidationProvider>
                        <ValidationProvider
                          rules="required|numeric|max:12"
                          tag="div"
                          v-slot="{ errors }"
                          class="d-flex flex-row flex-grow-1"
                        >
                          <i
                            :class="
                              errors.length > 0 ? 'red--text' : 'black--text'
                            "
                            class="material-icons-outlined mt-4 mr-3"
                            >phone</i
                          >
                          <v-text-field
                            class="font font-weight-medium"
                            label="Phone Number"
                            :error-messages="errors[0]"
                            hint="Enter directors' phone here ..."
                            v-model="director.phone"
                          />
                        </ValidationProvider>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-if="business.directors.length > 1"
                              small
                              v-on="on"
                              absolute
                              @click="removeRow(index)"
                              text
                              fab
                              right
                              color="error"
                              class="mt-3 mr-n4"
                              style="z-index: 10"
                            >
                              <i class="material-icons-outlined font-size-md"
                                >delete</i
                              >
                            </v-btn>
                          </template>
                          <span class="font font-size-sm">Remove Director</span>
                        </v-tooltip>
                      </div>
                      <div class="d-flex flex-row flex-grow-1 justify-end mt-2">
                        <v-btn
                          @click="addRow"
                          small
                          color="primary"
                          text
                          outlined
                        >
                          <span class="font font-weight-medium"
                            >Add New Row</span
                          >
                        </v-btn>
                      </div>
                    </div>
                    <span
                      class="text-uppercase font font-weight-bold font-size-sm mt-4"
                      >Details of a contact person</span
                    >
                    <div class="d-flex flex-row flex-grow-1">
                      <div
                        style="border-radius: 8px; background: rgb(232 232 232 / 41%);"
                        class="d-flex flex-row flex-grow-1 px-4 my-1"
                      >
                        <i class="material-icons-outlined mt-4 mr-3"
                          >account_circle</i
                        >
                        <ValidationProvider
                          rules="required"
                          tag="div"
                          v-slot="{ errors }"
                          class="d-flex flex-row flex-grow-1"
                        >
                          <v-text-field
                            class="font font-weight-medium"
                            label="Name"
                            :error-messages="errors[0]"
                            hint="Enter directors' full name here ..."
                            v-model="business.contactPerson.name"
                          />
                        </ValidationProvider>
                        <ValidationProvider
                          rules="required|email"
                          tag="div"
                          v-slot="{ errors }"
                          class="d-flex flex-row flex-grow-1"
                        >
                          <i
                            :class="
                              errors.length > 0 ? 'red--text' : 'black--text'
                            "
                            class="material-icons-outlined mt-4 mr-3"
                            >email</i
                          >
                          <v-text-field
                            class="font font-weight-medium"
                            label="Email Address"
                            :error-messages="errors[0]"
                            hint="Enter directors' email address here ..."
                            v-model="business.contactPerson.email"
                          />
                        </ValidationProvider>
                        <ValidationProvider
                          rules="required|numeric|max:12"
                          tag="div"
                          v-slot="{ errors }"
                          class="d-flex flex-row flex-grow-1"
                        >
                          <i
                            :class="
                              errors.length > 0 ? 'red--text' : 'black--text'
                            "
                            class="material-icons-outlined mt-4 mr-3"
                            >phone</i
                          >
                          <v-text-field
                            class="font font-weight-medium"
                            label="Phone Number"
                            :error-messages="errors[0]"
                            hint="Enter directors' phone here ..."
                            v-model="business.contactPerson.phone"
                          />
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="d-flex flex-row flex-grow-1">
                      <ValidationProvider
                        tag="div"
                        class="d-flex flex-row flex-grow-1"
                      >
                        <i class="material-icons-outlined mt-4 mr-3">store</i>
                        <v-text-field
                          class="font font-weight-medium"
                          label="Nature Of Business"
                          hint="Enter company's nature of business here ..."
                          v-model="business.natureOfBusiness"
                        />
                      </ValidationProvider>
                      <ValidationProvider
                        tag="div"
                        class="font d-flex flex-row flex-grow-1"
                        rules="required|numeric"
                        v-slot="{ errors }"
                      >
                        <i
                          :class="
                            errors.length > 0 ? 'red--text' : 'black--text'
                          "
                          class="material-icons-outlined mt-4 mr-3"
                          >credit_card</i
                        >
                        <v-text-field
                          v-model="business.amount"
                          label="Current Amount"
                          :error-messages="errors[0]"
                          :prefix="
                            currency && currency[0] ? currency[0].symbol : 'GHS'
                          "
                          hint="Enter customers' current balance or amount here"
                          class="font font-weight-medium font-size-sm"
                        />
                      </ValidationProvider>
                    </div>
                    <div class="d-flex flex-column flex-grow-1">
                      <ValidationProvider
                        tag="div"
                        class="d-flex flex-column flex-grow-1"
                      >
                        <i class="material-icons-outlined">attached</i>
                        <v-file-input
                          @change="onFileChanged"
                          :show-size="1000"
                          v-model="file"
                          label="Upload Form 3"
                          accept="image/png,image/jpeg,image/jpg,application/pdf"
                          class="font font-weight-medium"
                        >
                          <template #selection="{ text}">
                            <v-chip color="primary" label dark small>
                              {{ text }}
                            </v-chip>
                          </template>
                        </v-file-input>
                      </ValidationProvider>
                    </div>
                    <div class="d-flex flex-column flex-grow-1">
                      <v-btn
                        type="submit"
                        color="primary"
                        :disabled="loading"
                        :loading="loading"
                        class="mx-2 mt-3"
                      >
                        <span class="font font-weight-medium"
                          >Add Business</span
                        >
                      </v-btn>
                    </div>
                  </form>
                </ValidationObserver>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card
              class="box-shadow-light pt-3 pb-8 mb-4 mt-3 px-2"
              style="border-radius: 8px;width: 60%;margin: 0 auto;"
            >
              <v-card-text>
                <ValidationObserver
                  class="d-flex flex-column flex-grow-1"
                  v-slot="{ handleSubmit }"
                >
                  <form @submit.prevent="handleSubmit(addAsIndividual)">
                    <ValidationProvider
                      tag="div"
                      class="font d-flex flex-row flex-grow-1"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <i
                        :class="errors.length > 0 ? 'red--text' : 'black--text'"
                        class="material-icons-outlined mt-4 mr-3"
                        >people</i
                      >
                      <v-text-field
                        autofocus
                        class="font font-weight-medium d-flex flex-row flex-grow-1"
                        label="Customer Name"
                        clearable
                        :error-messages="errors[0]"
                        v-model="individual.name"
                        hint="Enter customer full name"
                      />
                    </ValidationProvider>
                    <ValidationProvider
                      tag="div"
                      class="font d-flex flex-row flex-grow-1"
                      rules="required|email"
                      v-slot="{ errors }"
                    >
                      <i
                        :class="errors.length > 0 ? 'red--text' : 'black--text'"
                        class="material-icons-outlined mt-4 mr-3"
                        >email</i
                      >
                      <v-text-field
                        class="font font-weight-medium d-flex flex-row flex-grow-1"
                        label=" Email"
                        clearable
                        :error-messages="errors[0]"
                        v-model="individual.email"
                        hint="Enter  email here"
                      />
                    </ValidationProvider>
                    <ValidationProvider
                      tag="div"
                      class="font d-flex flex-row flex-grow-1"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <i
                        :class="errors.length > 0 ? 'red--text' : 'black--text'"
                        class="material-icons-outlined mt-4 mr-3"
                        >phone</i
                      >
                      <v-text-field
                        autofocus
                        class="font font-weight-medium d-flex flex-row flex-grow-1"
                        label="Phone Number"
                        clearable
                        :error-messages="errors[0]"
                        v-model="individual.phone"
                        hint="Enter phone number here"
                      />
                    </ValidationProvider>

                    <div class="d-flex flex-row flex-grow-1 mt-3">
                      <ValidationProvider
                        tag="div"
                        class="font d-flex flex-row flex-grow-1"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <i
                          :class="
                            errors.length > 0 ? 'red--text' : 'black--text'
                          "
                          class="material-icons-outlined mt-4 mr-3"
                          >phone</i
                        >
                        <v-autocomplete
                          :items="cards"
                          class="  font font-weight-medium font-size-sm"
                          item-value="id"
                          v-model="individual.cardId"
                          label="Card Type"
                          item-text="name"
                          hint="Choose ID Card type ..."
                        /> </ValidationProvider
                      ><ValidationProvider
                        tag="div"
                        class="font d-flex flex-row flex-grow-1"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <i
                          :class="
                            errors.length > 0 ? 'red--text' : 'black--text'
                          "
                          class="material-icons-outlined mt-4 mr-3"
                          >map</i
                        >
                        <v-text-field
                          class="font font-weight-medium d-flex flex-row flex-grow-1"
                          label="ID Card Nuber"
                          clearable
                          :error-messages="errors[0]"
                          v-model="individual.cardNumber"
                          hint="Enter card number here"
                        />
                      </ValidationProvider>
                    </div>
                    <div class="d-flex flex-row flex-grow-1 mt-2">
                      <ValidationProvider
                        tag="div"
                        class="font d-flex flex-row flex-grow-1"
                      >
                        <i class="material-icons-outlined mt-4 mr-3"
                          >credit_card</i
                        >
                        <v-text-field
                          v-model="individual.amount"
                          :prefix="
                            currency && currency[0] ? currency[0].symbol : 'GHS'
                          "
                          label="Current Amount"
                          hint="Enter customers' current balance or amount here"
                          class="font font-weight-medium font-size-sm"
                        />
                      </ValidationProvider>
                    </div>
                    <div class="d-flex flex-row flex-grow-1">
                      <ValidationProvider
                        tag="div"
                        class="font d-flex flex-row flex-grow-1"
                      >
                        <i class="material-icons-outlined mt-4 mr-3"
                          >location_on</i
                        >
                        <v-textarea
                          v-model="individual.residentAddress"
                          class="font font-weight-medium d-flex flex-row flex-grow-1"
                          label="Resident Address"
                          clearable
                          hint="Enter resident address here"
                        />
                      </ValidationProvider>
                    </div>

                    <div class="d-flex flex-column flex-grow-1">
                      <v-btn
                        type="submit"
                        color="primary"
                        :disabled="loading"
                        :loading="loading"
                        class="mx-2 mt-3"
                      >
                        <span class="font font-weight-medium"
                          >Add Customer</span
                        >
                      </v-btn>
                    </div>
                  </form>
                </ValidationObserver>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card
              class="box-shadow-light pt-3 pb-8 mb-4 mt-3 px-2"
              style="border-radius: 8px;width: 60%;margin: 0 auto;"
            >
              <v-card-text>
                <ValidationObserver
                  class="d-flex flex-column flex-grow-1"
                  v-slot="{ handleSubmit }"
                >
                  <form @submit.prevent="handleSubmit(addAsSupplier)">
                    <ValidationProvider
                      tag="div"
                      class="font d-flex flex-row flex-grow-1"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <i
                        :class="errors.length > 0 ? 'red--text' : 'black--text'"
                        class="material-icons-outlined mt-4 mr-3"
                        >verified_user</i
                      >
                      <v-text-field
                        autofocus
                        class="font font-weight-medium d-flex flex-row flex-grow-1"
                        label="Business Registration Number"
                        clearable
                        :error-messages="errors[0]"
                        v-model="supplier.businessRegistrationNumber"
                        hint="Enter registration no. here"
                      />
                    </ValidationProvider>
                    <div class="d-flex flex-row flex-grow-1">
                      <ValidationProvider
                        tag="div"
                        class="font d-flex flex-row flex-grow-1"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <i
                          :class="
                            errors.length > 0 ? 'red--text' : 'black--text'
                          "
                          class="material-icons-outlined mt-4 mr-3"
                          >store</i
                        >
                        <v-text-field
                          autofocus
                          class="font font-weight-medium d-flex flex-row flex-grow-1"
                          label="Business Name"
                          clearable
                          :error-messages="errors[0]"
                          v-model="supplier.name"
                          hint="Enter business name here"
                        />
                      </ValidationProvider>
                      <ValidationProvider
                        tag="div"
                        class="font d-flex flex-row flex-grow-1"
                        rules="required|email"
                        v-slot="{ errors }"
                      >
                        <i
                          :class="
                            errors.length > 0 ? 'red--text' : 'black--text'
                          "
                          class="material-icons-outlined mt-4 mr-3"
                          >email</i
                        >
                        <v-text-field
                          class="font font-weight-medium d-flex flex-row flex-grow-1"
                          label="Business Email"
                          clearable
                          :error-messages="errors[0]"
                          v-model="supplier.email"
                          hint="Enter business email here"
                        />
                      </ValidationProvider>
                    </div>

                    <div class="d-flex flex-row flex-grow-1">
                      <ValidationProvider
                        tag="div"
                        class="font d-flex flex-row flex-grow-1"
                        rules="required|numeric|max:12"
                        v-slot="{ errors }"
                      >
                        <i
                          :class="
                            errors.length > 0 ? 'red--text' : 'black--text'
                          "
                          class="material-icons-outlined mt-4 mr-3"
                          >phone</i
                        >
                        <v-text-field
                          class="font font-weight-medium d-flex flex-row flex-grow-1"
                          label="Business Contact"
                          clearable
                          :error-messages="errors[0]"
                          v-model="supplier.contact"
                          hint="Enter business contact here"
                        /> </ValidationProvider
                      ><ValidationProvider
                        tag="div"
                        class="font d-flex flex-row flex-grow-1"
                      >
                        <i class="material-icons-outlined mt-4 mr-3">map</i>
                        <v-text-field
                          class="font font-weight-medium d-flex flex-row flex-grow-1"
                          label="Business Address"
                          clearable
                          v-model="supplier.address"
                          hint="Enter address here"
                        />
                      </ValidationProvider>
                    </div>
                    <div class="d-flex flex-row flex-grow-1">
                      <ValidationProvider
                        tag="div"
                        class="font d-flex flex-row flex-grow-1"
                      >
                        <i class="material-icons-outlined mt-4 mr-3"
                          >location_on</i
                        >
                        <v-text-field
                          v-model="supplier.officeLocation"
                          class="font font-weight-medium d-flex flex-row flex-grow-1"
                          label="Office Location"
                          clearable
                          hint="Enter office location here"
                        />
                      </ValidationProvider>
                      <ValidationProvider
                        tag="div"
                        class="font d-flex flex-row flex-grow-1"
                      >
                        <i class="material-icons-outlined mt-4 mr-3"
                          >credit_card</i
                        >
                        <v-text-field
                          v-model="supplier.tin"
                          class="font font-weight-medium d-flex flex-row flex-grow-1"
                          label="Business TIN"
                          clearable
                          hint="Enter business tin here"
                        />
                      </ValidationProvider>
                    </div>
                    <div class="d-flex flex-row flex-grow-1 mt-3">
                      <ValidationProvider
                        tag="div"
                        class="font d-flex flex-row flex-grow-1"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <i
                          :class="
                            errors.length > 0 ? 'red--text' : 'black--text'
                          "
                          class="material-icons-outlined mt-4 mr-3"
                          >phone</i
                        >
                        <v-autocomplete
                          :items="categoryList"
                          class="  font font-weight-medium font-size-sm"
                          v-model="supplier.category"
                          label="Invoice Type"
                          item-text="name"
                          hint="Choose Invoice Type ..."
                        />
                      </ValidationProvider>
                    </div>
                    <span
                      class="text-uppercase font font-weight-bold font-size-sm mt-4"
                      >Details of a contact person</span
                    >
                    <div class="d-flex flex-row flex-grow-1">
                      <div
                        style="border-radius: 8px; background: rgb(232 232 232 / 41%);"
                        class="d-flex flex-row flex-grow-1 px-4 my-1"
                      >
                        <i class="material-icons-outlined mt-4 mr-3"
                          >account_circle</i
                        >
                        <ValidationProvider
                          rules="required"
                          tag="div"
                          v-slot="{ errors }"
                          class="d-flex flex-row flex-grow-1"
                        >
                          <v-text-field
                            class="font font-weight-medium"
                            label="Name"
                            :error-messages="errors[0]"
                            hint="Enter directors' full name here ..."
                            v-model="supplier.contactPerson.name"
                          />
                        </ValidationProvider>
                        <ValidationProvider
                          rules="required|email"
                          tag="div"
                          v-slot="{ errors }"
                          class="d-flex flex-row flex-grow-1"
                        >
                          <i
                            :class="
                              errors.length > 0 ? 'red--text' : 'black--text'
                            "
                            class="material-icons-outlined mt-4 mr-3"
                            >email</i
                          >
                          <v-text-field
                            class="font font-weight-medium"
                            label="Email Address"
                            :error-messages="errors[0]"
                            hint="Enter directors' email address here ..."
                            v-model="supplier.contactPerson.email"
                          />
                        </ValidationProvider>
                        <ValidationProvider
                          rules="required|numeric|max:12"
                          tag="div"
                          v-slot="{ errors }"
                          class="d-flex flex-row flex-grow-1"
                        >
                          <i
                            :class="
                              errors.length > 0 ? 'red--text' : 'black--text'
                            "
                            class="material-icons-outlined mt-4 mr-3"
                            >phone</i
                          >
                          <v-text-field
                            class="font font-weight-medium"
                            label="Phone Number"
                            :error-messages="errors[0]"
                            hint="Enter directors' phone here ..."
                            v-model="supplier.contactPerson.phone"
                          />
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="d-flex flex-row flex-grow-1">
                      <ValidationProvider
                        tag="div"
                        class="d-flex flex-row flex-grow-1"
                      >
                        <i class="material-icons-outlined mt-4 mr-3">store</i>
                        <v-text-field
                          class="font font-weight-medium"
                          label="Area Of Trade"
                          hint="Enter company's area of trade here ..."
                          v-model="supplier.areaOfTradeOrSupply"
                        />
                      </ValidationProvider>
                      <ValidationProvider
                        tag="div"
                        class="font d-flex flex-row flex-grow-1"
                        rules="required|numeric"
                        v-slot="{ errors }"
                      >
                        <i
                          :class="
                            errors.length > 0 ? 'red--text' : 'black--text'
                          "
                          class="material-icons-outlined mt-4 mr-3"
                          >credit_card</i
                        >
                        <v-text-field
                          v-model="supplier.creditPurchasesAmount"
                          label=" Credit Purchases Amount"
                          :error-messages="errors[0]"
                          :prefix="
                            currency.length > 0 ? currency[0].symbol : 'GHS'
                          "
                          hint="Enter customers' credit purchases amount here"
                          class="font font-weight-medium font-size-sm"
                        />
                      </ValidationProvider>
                    </div>
                    <div class="d-flex flex-column flex-grow-1">
                      <ValidationProvider
                        tag="div"
                        class="d-flex flex-column flex-grow-1"
                      >
                        <i class="material-icons-outlined">attached</i>
                        <v-file-input
                          @change="onSupplierFieChange"
                          :show-size="1000"
                          v-model="file"
                          label="Upload Business Registration Cert."
                          accept="image/png,image/jpeg,image/jpg,application/pdf"
                          class="font font-weight-medium"
                        >
                          <template #selection="{ text}">
                            <v-chip color="primary" label dark small>
                              {{ text }}
                            </v-chip>
                          </template>
                        </v-file-input>
                      </ValidationProvider>
                    </div>
                    <div class="d-flex flex-column flex-grow-1">
                      <v-btn
                        type="submit"
                        color="primary"
                        :disabled="loading"
                        :loading="loading"
                        class="mx-2 mt-3"
                      >
                        <span class="font font-weight-medium"
                          >Add Supplier</span
                        >
                      </v-btn>
                    </div>
                  </form>
                </ValidationObserver>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
    <SnackBar
      :showSnackBar="showSnackBar"
      :message="message"
      :status="status"
    />
  </v-container>
</template>
<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { email, numeric, required, max, min } from "vee-validate/dist/rules";
import { mapGetters } from "vuex";
import { decimals } from "../../utils/resolvers";
import SnackBarMixins from "../../mixins/SnackBarMixins";
extend("required", {
  ...required,
  message: "Field is required"
});
extend("numeric", {
  ...numeric,
  message: "Field accept only number inputs"
});
extend("max", {
  ...max,
  message: "Invalid length provided"
});
extend("min", {
  ...min,
  message: "Invalid length provided"
});
extend("email", {
  ...email,
  message: "Invalid email address provided"
});
extend("decimals", decimals);
export default {
  name: "NewCustomer",
  components: {
    ValidationProvider,
    ValidationObserver,
    PageTitle: () => import(`../../components/PageTitle`),
    SnackBar: () => import(`../../components/SnackBar`)
  },
  data() {
    return {
      tag: null,
      tagList: ["As a business", "As an Individual", "As a Supplier"],
      categoryList: ["Standard", "Flat"],
      file: null,
      form3Upload: null,
      business: {
        businessRegistrationNumber: "",
        name: "",
        email: "",
        contact: null,
        address: null,
        tin: null,
        officeLocation: null,
        natureOfBusiness: "",
        creditPurchaseAmount: null,
        contactPerson: {
          name: "",
          phne: "",
          email: ""
        },
        directors: [
          {
            name: "",
            phone: "",
            email: ""
          }
        ]
      },
      individual: {
        name: "",
        email: "",
        phone: "",
        residentAddress: "",
        amount: 0,
        cardId: "",
        cardNumber: ""
      },
      businessRegistrationCert: "",
      supplier: {
        businessRegistrationNumber: "",
        name: "",
        email: "",
        contact: null,
        address: null,
        tin: null,
        category: null,
        officeLocation: null,
        areaOfTradeOrSupply: "",
        creditPurchasesAmount: null,
        contactPerson: {
          name: "",
          phone: "",
          email: ""
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      loading: "getIsLoading",
      resetFormState: "getResetFormState",
      resetFormValues: "customers/getResetFormValues",
      supplierResetFormValues: "suppliers/getResetFormValues",
      showSnackBar: "getSnackBarState",
      message: "getSnackBarMessage",
      status: "getSnackBarStatus",
      cards: "customers/getIDCardList",
      currency: "currency/getSystemCurrency"
    })
  },
  watch: {
    resetFormState(state) {
      if (state) {
        this.business = { ...this.resetFormValues };
        this.file = this.form3Upload = this.businessRegistrationCert = null;
        this.supplier = { ...this.supplierResetFormValues };
      }
      this.$store.dispatch("resetFormState", false, { root: true });
    }
  },
  mixins: [SnackBarMixins],
  methods: {
    onFileChanged(file) {
      if (file) {
        let form = new FormData();
        form.append("file", file, file?.name);
        this.form3Upload = form;
      }
    },
    onSupplierFieChange(file) {
      if (file) {
        let form = new FormData();
        form.append("file", file, file?.name);
        this.businessRegistrationCert = form;
      }
    },
    addRow() {
      this.business.directors.push({
        name: "",
        email: ""
      });
    },
    removeRow(index) {
      this.business.directors.splice(index, 1);
    },
    addAsBusiness() {
      let payload = {
        body: this.business,
        upload: this.form3Upload
      };
      this.$store.dispatch("customers/createAsBusiness", payload);
    },
    addAsIndividual() {
      this.$store.dispatch("customers/createAsIndividual", this.individual);
    },
    addAsSupplier() {
      this.$store.dispatch("suppliers/create", {
        body: this.supplier,
        upload: this.businessRegistrationCert
      });
    }
  },
  created() {
    this.$store.dispatch("customers/cardList");
    this.$store.dispatch("currency/getSystemCurrency");
    this.$store.dispatch("resetSnackBarState", false, { root: true });
  }
};
</script>
